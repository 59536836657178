import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import logotipo from './logo.svg';

import { FetchAPI } from '@softwone/helpers';

const urlServidor = "https://35.225.97.245:4700";

const SEND_MAIL = `
    query sendMailSoporte($nombre: String!, $correo: String!, $asunto: String!, $detalle: String!, $remitente: String, $desde: String) {
        sendMailSoporte(nombre: $nombre, correo: $correo, asunto: $asunto, detalle: $detalle, remitente: $remitente, desde: $desde)
    }
`;

const sendMailSoporte = async (variables) => {
    try {

        let { sendMailSoporte } = await FetchAPI.graphql({
            variables,
            url: urlServidor,
            query: SEND_MAIL
        });

        return sendMailSoporte;

    } catch({message}) { throw new Error(message); }
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary">
            {"Copyright © "}
            <Link color="inherit" href="https://kalenday.softwone.com">
                Softwone International S.A. de C.V.
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: "auto",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
    },
}));

export default function StickyFooter() {
    const classes = useStyles();

    const cleanState = { nombre: "", correo: "", asunto: "", detalle: "" };
    const cleanError = {
        nombre: { error: false, helperText: "" },
        correo: { error: false, helperText: "" },
        asunto: { error: false, helperText: "" },
        detalle: { error: false, helperText: "" },
        error: { error: false, helperText: "" }
    };

    const [state, setState] = useState(cleanState);
    const [error, setError] = useState(cleanError);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(null);

    const inputs = [{
        disabled,
        error: error.nombre.error,
        id: "nombre",
        value: state.nombre,
        label: "Hola, ¿cuál es tu nombre?",
        placeholder: "Capture su nombre completo",
        helperText: error.nombre.helperText,
        onChange: ftHandleChange
    },{
        disabled,
        error: error.correo.error,
        id: "correo",
        value: state.correo,
        label: "¿Cómo te contactamos?",
        placeholder: "Capture un teléfono o cualquier otro medio para contactarlo",
        helperText: error.correo.helperText,
        onChange: ftHandleChange
    },{
        disabled,
        error: error.asunto.error,
        id: "asunto",
        value: state.asunto,
        label: "¿Cuál es el detalle?",
        placeholder: "En breves palabras defina el asunto del mensaje",
        helperText: error.asunto.helperText,
        onChange: ftHandleChange
    },{
        disabled,
        error: error.detalle.error,
        id: "detalle",
        value: state.detalle,
        label: "¿Podrías darnos más información?",
        placeholder: "Aquí puedes detallar todo la información necesaria para que podamos ayudarte",
        helperText: error.detalle.helperText,
        onChange: ftHandleChange,
        multiline: true
    }];

    function ftHandleChange({ target:{ value, id }}) { setState({ ...state, [id]: value }); }

    function ftValidar(data) {

        let keys = Object.keys(data),
            newObjError = {};

        keys.forEach(key => { if(!/^.+$/.test(data[key])) newObjError = {
            ...newObjError,
            [key]: {
                error: true,
                helperText: "Para poder ayudarte necesitamos que se capture algo válido en este campo"
            }
        }  });

        setError({ ...cleanError, ...newObjError });

        return Object.keys(newObjError).length === 0;

    }

    function ftEnviar() {
        async function enviar() {
            try {

                setDisabled(true);

                const { nombre, correo, asunto, detalle } = state;

                if(!ftValidar({ nombre, correo, asunto, detalle })) { setDisabled(false); return; }

                await sendMailSoporte({ ...state, remitente: "Soporte para Softwone International S.A. de C.V.", desde: "soporte@softwone.com" });

                setError({ ...cleanError });
                setState({ ...cleanState });
                setSuccess("Muchas gracias, ya hemos recibido tu correo. Haremos todo lo posible por darte una pronta respuesta.");

            } catch({message: helperText}) {
                setSuccess(null);
                setError({ ...cleanError, error: { error: true, helperText } });
            }
            setDisabled(false);
        } enviar();
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Container component="main" className={classes.main} maxWidth="sm">
                <div style={{ margin: "0, auto", textAlign: "center" }}>
                    <img src={logotipo} alt="logotipo" style={{ maxWidth: "500px", width: "100%", height: "auto" }} />
                </div>
                <br />
                <Typography variant="h2" component="h1" gutterBottom>
                    Soporte técnico
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    {"Softwone International S.A. de C.V."}
                </Typography>
                <Typography variant="body1">
                    Si tienes algún detalle o sugerencia puedes utilizar el siguiente formulario para que podamos darle seguimiento.
                </Typography>
                <br /><br />
                {
                    (error.error.error || success) && <><Typography variant="body1" color={success ? "primary" : "error"}>
                        { error.error.error && <>Ocurrio el siguiente problema: <br /><b>{ error.error.helperText }</b></> }
                        { success && <b>{success}</b> }
                    </Typography>
                    <br /> <br /></>
                }
                <Grid container spacing={1}>
                    {
                        inputs.map(({disabled, error, id, value, label, placeholder, helperText, onChange, multiline = false }, key) => (<Grid item xs={12} key={key}>
                            <TextField
                                disabled={disabled}
                                error={error}
                                id={id}
                                value={value}
                                label={label}
                                placeholder={placeholder}
                                helperText={helperText}
                                onChange={onChange}
                                multiline={multiline}
                                fullWidth
                            />
                        </Grid>))
                    }
                    <Grid item xs={12}>
                        <br /><br />
                        <Button
                            disabled={disabled}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<SendIcon />}
                            onClick={ftEnviar}
                            fullWidth
                        >
                            { disabled ? "Espere un momento..." : "Enviar" }
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <hr />
                <br />
                {/* <Grid container spacing={1}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {"Preguntas frecuentes"}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>{"1. ¿Cuántas citas puedo agendar?"}</b>
                        {" "}
                        <i>{"- Solo puedes agendar la primer cita. Para las demás tendrás que llamar directamente a la clínica para que te puedan agendar una cita"}</i>
                    </Typography>
                </Grid> */}
                <br />
            </Container>
            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Typography variant="body1">
                        {"Puedes contactarnos directamente al siguiente correo:"}
                        {" "}
                        <Link color="inherit" href="mailto:soporte@softwone.com">
                            soporte@softwone.com
                        </Link>
                    </Typography>
                    <Copyright />
                </Container>
            </footer>
        </div>
    );
}
